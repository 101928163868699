import React from "react";
import popupImg from "../../assets/popup/popup.jpeg";

interface PopupProps {
  onClose: () => void;
  onDoNotShow: () => void;
}

const Popup: React.FC<PopupProps> = ({ onClose, onDoNotShow }) => {
  return (
    <div className="fixed top-36 left-10 w-screen h-screen pointer-events-none z-50 flex items-start justify-start">
      <div
        className="bg-white shadow-lg rounded-lg relative flex flex-col justify-between pointer-events-auto"
        style={{ width: "12cm", height: "15cm" }}
      >
        <img
          src={popupImg}
          alt={"Popup"}
          className="mx-auto w-full h-[90%] object-cover"
        />
        <div className="flex justify-between h-[10%]">
          <button
            className="bg-gray-300 text-black w-[50%] px-4 py-2 rounded-xs"
            onClick={onDoNotShow}
          >
            1일 동안 보지 않기
          </button>
          <button
            className="bg-blue-400 text-white w-[50%] px-4 py-2 rounded-xs"
            onClick={onClose}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
