import Main1 from "../assets/photoSlider/1.jpeg";
import Main2 from "../assets/photoSlider/2.jpg";
import Main3 from "../assets/photoSlider/3.jpg";
import Main4 from "../assets/photoSlider/4.jpg";
import Main5 from "../assets/photoSlider/5.jpg";

const photoSlider = [
  {
    src: Main1,
    alt: "Main Background 1",
  },
  {
    src: Main2,
    alt: "Main Background 2",
  },
  {
    src: Main3,
    alt: "Main Background 3",
  },
  {
    src: Main4,
    alt: "Main Background 4",
  },
  {
    src: Main5,
    alt: "Main Background 5",
  },
  // {
  //   type: "video",
  //   src: "/img/video.mp4",
  //   alt: "Sample Video 2",
  // },
];

export default photoSlider;
