import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import photoSlider from "../../data/photoSlider";
// import ReactPlayer from "react-player";

const styles = {
  slider: {
    transform: "translate3d(0, 0, 0)",
    transitionDelay: "10ms",
  },
};

const PhotoSlider: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  return (
    <div className="flex flex-col w-full h-[390px] md:h-[660px] lg:flex-row lg:h-[860px]">
      <div className="w-full h-[260px] md:h-[440px] lg:h-[860px] lg:w-2/3">
        <Slider {...settings}>
          {photoSlider.map((item, index) => (
            <div
              key={index}
              className="w-full h-[260px] md:h-[440px] lg:h-[860px]"
            >
              <img
                src={item.src}
                alt={item.alt}
                className="w-full h-full object-cover transition-opacity duration-500 ease-in-out"
                loading="lazy"
                style={styles.slider}
              />
            </div>
          ))}
          {/* {photoSlider.map((item, index) => (
            <div
              key={index}
              className="w-full h-[260px] md:h-[440px] lg:h-[860px]"
            >
              {item.type === "video" ? (
                <ReactPlayer
                  url={item.src}
                  width="100%"
                  height="100%"
                  playing={false} // 자동 재생 여부
                  muted
                  loop
                  controls // 동영상 플레이어 컨트롤 표시
                />
              ) : (
                <img
                  src={item.src}
                  alt={item.alt}
                  className="w-full h-full object-cover transition-opacity duration-500 ease-in-out"
                  loading="lazy"
                  style={styles.slider}
                />
              )}
            </div>
          ))} */}
        </Slider>
      </div>
      <div className="w-full h-[130px] flex bg-black md:h-[220px] lg:w-1/3 lg:h-full">
        <div className="text-white bg-[#213260] bg-opacity-90 w-full h-full p-4 xl:pl-12 flex flex-col items-center justify-center lg:items-start">
          <p className="tenada text-xl pb-1 xs:text-2xl sm:text-2xl md:text-3xl md:pb-1 lg:text-4xl lg:pb-4 font-bold">
            (주) 미즈캠핑 자연인
          </p>
          <p className="gmarketMedium font-bold hidden sm:block md:text-lg md:pl-2 md:pb-2 lg:text-xl ">
            자연과의 완벽한 조화를 경험하세요!
          </p>
          <p className="gmarketMedium font-bold hidden lg:block md:text-lg md:pl-2 md:pb-4 lg:text-xl ">
            수많은 설치사례가 증명합니다.
          </p>
          <p className="gmarketLight text-ms xs:text-ms sm:text-sm md:text-lg md:pl-2 md:pb-2 lg:text-lg lg:pt-2">
            정통 글램핑텐트, 특수/주문형텐트, 인디언텐트 등을 현장 설정에 맞게
          </p>
          <p className="text-yellow-300 font-bold pb-1 text-sm xs:text-md sm:text-md md:text-xl md:pl-2 lg:text-2xl ">
            컨설팅 {">"} 시공 {">"} 준공 {">"} 사업장 OPEN
          </p>
          <p className="gmarketLight text-ms xs:text-ms sm:text-sm md:text-lg md:pl-2 lg:text-xl ">
            하여 드립니다
          </p>
          <p className="gmarketLight hidden lg:block lg:text-xl lg:pl-2 lg:pt-10">
            야영장, 캠핑장, 각종 관광농원, 레저 리조트 등을
          </p>
          <p className="gmarketLight hidden lg:block lg:text-xl lg:pl-2">
            현지 지형 및 설정에 맞게
          </p>
          <p className="gmarketLight hidden lg:block lg:text-xl lg:pl-2">
            컨설팅 {">"} 시공 {">"} 준공 {">"} 사업장 OPEN 하여 드립니다
          </p>
        </div>
      </div>
    </div>
  );
};

export default PhotoSlider;
