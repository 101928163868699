import { TextField, Button, CircularProgress, Checkbox } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailLayout from "../../components/layout/DetailLayout";
import categoryItems from "../../data/categoryItems";
import { BoardFormData, BoardFormErrorMessages } from "../../types/boardTypes";
import personalInfo from "../../data/personalInfo";

const Board: React.FC = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<BoardFormData>({
    name: "",
    phone: "",
    email: "",
    title: "",
    content: "",
    captcha: "",
    checkbox: false,
  });

  const [captcha, setCaptcha] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<BoardFormErrorMessages>({
    name: "",
    phone: "",
    email: "",
    title: "",
    content: "",
    captcha: "",
  });
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const generateCaptcha = () => {
    const randomStr = Math.random().toString(36).substring(2, 8);
    setCaptcha(randomStr.toUpperCase());

    // 폼의 captcha 필드를 초기화하고 유효성 검사 실행
    setFormData((prev) => ({ ...prev, captcha: "" }));
    validateFormData();
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const validateField = (name: string, value: string) => {
    let message = "";

    // 빈 값이면 에러 메시지를 반환하지 않음
    if (!value) return message;

    if (name === "phone" && !/^(010|011)\d*$/.test(value)) {
      message =
        "전화번호는 010 또는 011로 시작해야 하며, 하이픈(-) 없이 입력해 주세요.";
    } else if (name === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      message = "유효한 이메일 형식이어야 합니다.";
    } else if (name === "captcha" && value !== captcha) {
      message = "자동입력방지 문자가 일치하지 않습니다.";
    }
    return message;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    const validationMessage = validateField(name, value);
    setError((prev) => ({ ...prev, [name]: validationMessage }));
  };

  const validateFormData = () => {
    const newErrors: BoardFormErrorMessages = {
      name: validateField("name", formData.name),
      phone: validateField("phone", formData.phone),
      email: validateField("email", formData.email),
      title: validateField("title", formData.title),
      content: validateField("content", formData.content),
      captcha: validateField("captcha", formData.captcha),
    };
    setError(newErrors);

    const hasErrors = Object.values(newErrors).some(
      (errorMessage) => errorMessage !== ""
    );

    // 모든 필드가 올바르게 입력되고 체크박스가 체크되었는지 확인
    setIsFormValid(!hasErrors && formData.checkbox);
  };

  useEffect(() => {
    // 폼 데이터나 에러가 변경될 때마다 유효성 검사를 실행
    validateFormData();
  }, [formData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid) return;
    setLoading(true);

    const API_URL = `https://aurorafucntion.azurewebsites.net/api/WriteNormalBoard?code=7o6gsQV5xRINbEu5LM6rEPtfugrE5axR1B6Hox5q3punAzFuxFIP6w==`;

    try {
      const response = await axios.post(
        API_URL,
        {
          Company: "mizcamping",
          TableType: "QnaBoard",
          PartitionKey: "QnaBoard",
          Title: formData.title,
          Content: JSON.stringify({
            Name: formData.name,
            TelNumber: formData.phone,
            Email: formData.email,
            Content: formData.content,
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.IsSuccess) {
        alert("견적문의 감사합니다. 확인 후 연락드리겠습니다.");
        setFormData({
          name: "",
          phone: "",
          email: "",
          title: "",
          content: "",
          captcha: "",
          checkbox: false,
        });
        generateCaptcha();
        navigate("/Board");
      } else {
        alert("게시글 작성에 실패했습니다.");
      }
    } catch (error) {
      console.error("게시글 작성 중 오류 발생", error);
      alert("문제가 발생했습니다. 나중에 다시 시도해 주세요.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DetailLayout title="견적 요청">
      <div className="relative top-[-140px] w-full flex justify-center items-center z-40 rounded-lg pt-2 xxs:h-[90px] xs:h-[90px] sm:h-[90px] md:h-[100px] lg:h-[100px]">
        <div className="flex flex-row w-[70%] h-full text-center rounded-lg justify-evenly items-center xxs:text-xs xs:text-xs sm:text-sm md:text-md lg:text-xl">
          {categoryItems["견적요청"].list.map((item: string, index: number) => (
            <p
              key={index}
              className={`flex text-center rounded-2xl border-gray-300 h-full justify-center items-center shadow-lg xxs:text-lg xs:text-lg sm:text-xl md:text-xl lg:text-2xl text-black bg-blue-200 w-[50%]`}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
      <div className="flex relative top-[-100px] justify-center w-full">
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-[80%]">
          <TextField
            label="이름"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            error={Boolean(error.name)}
            helperText={error.name}
            required
          />
          <TextField
            label="전화번호"
            name="phone"
            variant="outlined"
            value={formData.phone}
            onChange={handleChange}
            error={Boolean(error.phone)}
            helperText={error.phone}
            required
          />
          <TextField
            label="이메일"
            name="email"
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            error={Boolean(error.email)}
            helperText={error.email}
            required
          />
          <label className="flex items-center gap-2">
            * 이메일 주소를 정확히 입력하셔야 메일로 답변을 받아보실 수
            있습니다.
          </label>
          <TextField
            label="제목"
            name="title"
            variant="outlined"
            value={formData.title}
            onChange={handleChange}
            error={Boolean(error.title)}
            helperText={error.title}
            required
          />
          <TextField
            label="내용"
            name="content"
            variant="outlined"
            multiline
            rows={6}
            value={formData.content}
            onChange={handleChange}
            error={Boolean(error.content)}
            helperText={error.content}
            required
          />
          <div className="flex items-center gap-2">
            <p style={{ fontSize: "24px", letterSpacing: "3px" }}>{captcha}</p>
            <Button type="button" onClick={generateCaptcha}>
              새로고침
            </Button>
          </div>
          <TextField
            label="자동입력방지 문자"
            name="captcha"
            variant="outlined"
            value={formData.captcha}
            onChange={handleChange}
            error={Boolean(error.captcha)}
            helperText={error.captcha}
            required
          />
          <div className="max-h-[200px] overflow-y-auto p-4 border border-gray-300 rounded bg-gray-100">
            <p className="font-bold">{"<개인정보 수집∙이용에 대한 동의>"}</p>
            <p className="whitespace-pre-line">{personalInfo}</p>
          </div>
          <label className="flex flex-row items-center gap-2">
            <Checkbox
              checked={formData.checkbox}
              onChange={() =>
                setFormData((prev) => ({ ...prev, checkbox: !prev.checkbox }))
              }
              color="primary"
            />
            개인정보 처리방침에 동의합니다.
          </label>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isFormValid} // 유효성 검사 상태에 따라 활성화/비활성화
          >
            {loading ? <CircularProgress size="1.5rem" /> : "작성하기"}
          </Button>
        </form>
      </div>
    </DetailLayout>
  );
};

export default Board;
